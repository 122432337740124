import styled from "styled-components";
import Carousel from "react-elastic-carousel";

export const CustomCarousel = styled(Carousel)`
background-color: transparent;
@media only screen and (max-width: 450px) {
  height: auto;
  &.item0 {
    height: 610px;
  }
  &.item1 {
    height: 770px;
  }
}
`
export const Wrapper = styled.div`
  background: #1e232e;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 33px;
  /* min-height: 600px; */
  overflow: hidden;
  gap: 40px;
  @media only screen and (max-width: 768px) {
    overflow: hidden;
    min-height: 330px;
    height: auto;
  }

  @media only screen and (max-width: 450px) {
    /* padding-left: 24px;
        padding-right: 24px; */
  }
`;

export const Title = styled.h1`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 120%;
  /* or 62px */
  text-align: center;
  letter-spacing: -0.3px;

  color: #d9d9d9;
  margin: 0;
  position: relative;
  z-index: 2;
  /* padding-top: 100px; */

  @media only screen and (max-width: 768px) {
    /* font-size: 24px; */
    padding-top: 30px;
    font-size: 28px;
    line-height: 110%;
  }
`;

export const BG = styled.img`
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 3;
  /* height: 596px; */
  width: 100%;
  object-fit: cover;

  @media only screen and (max-width: 768px) {
    /* height: 1200px; */
    top: 16px;
    height: calc(100% + 96px);
    /* height: 2000px; */
    /* transform: scale(1.5); */
  }
`;

// estilos carrusel

export const WrapperCarrousel = styled.div`
    min-height: 330px;
    max-width: 1080px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  gap: 16px;
    @media only screen and (max-width: 1200px) {
      max-width: 100%;
      width: 100%;
    }
    @media only screen and (max-width: 450px) {
        min-height: unset;
        width: 100%;
        max-width: unset;
    }
`

export const PaginationContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
    position: relative;
    z-index: 99999;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    /* gap: 0; */
  }
`

export const Bullet = styled.div`
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  &.active {
    background-color: #7F56D9; /* Cambia el color de fondo según sea necesario */
  }
`

export const FirstImageCarrousel = styled.img`
  height: 278px;
  max-height: 278px;
  min-width: 1060px;
  position: relative;
  z-index: 999;
  @media only screen and (max-width: 450px) {
    max-height: unset;
    min-height: unset;
    height: unset;
    min-width: unset;
  }
`
  
export const SecondImageCarrousel = styled.img`
  max-height: 505px;
  position: relative;
  z-index: 999;
`
